import React, { useState } from 'react';
import SensorWrap from './SensorWrap.styles';
import { CloseOutlined, EyeOutlined } from '@ant-design/icons';
import { Button, Card } from 'antd';
import { Link } from 'react-router-dom';
import styled from "styled-components";
import Modal from '@iso/components/Feedback/Modal';
import IntlMessages from '@iso/components/utility/intlMessages';
import { sensorService } from '../../../../../services/sensor.service';

const SensorList = (props) => {
    const [visibleDeleteSensor, setVisibleDeleteSensor] = useState(false);
    const [sensorId, setSensorId] = useState(null);
    const [assetId, setAssetId] = useState(null);

    const onHandleDeleteSensor = (assetSensor) => {
        setVisibleDeleteSensor(true);
        setSensorId(assetSensor.sensor_id);
        setAssetId(assetSensor.asset_id);
    }

    const handleCancelSensor = () => {
        setVisibleDeleteSensor(false);
    } 

    const handleDeleteSensor = () => {
        sensorService.removeSensorFromAsset(sensorId, assetId).then(res => {
            if(res.code === '0000'){
                setVisibleDeleteSensor(false);
                props.getSensors();
            }
        });
    }

    return (
        <SensorWrap>
            {
                props.data.map((assetSensor, index) => {
                    return (
                        <div className="wrap-card-sensor">
                            <div className="wrap-btn-sensor">
                                <Link to={'manage-sensors'}>
                                    <Button type="link" className="btn-n-br btn-view-sensor" onClick="">
                                        <EyeOutlined />
                                    </Button>
                                </Link>

                                <Button
                                    type="link"
                                    className="btn-n-br btn-delete-sensor"
                                    onClick={onHandleDeleteSensor.bind(this, assetSensor)}
                                >
                                    <CloseOutlined/>
                                </Button>
                            </div>

                            <Card 
                                title={assetSensor.sensor.name} 
                                bordered={true} 
                                className="card-sensor-title"
                            >                                
                                <div>
                                    <p><b> Device: </b> { assetSensor.sensor.device?.terminal_id }</p>
                                    <p><b> Vendor: </b> { assetSensor.sensor.device?.supplier }</p>
                                    <p><b> Created Date: </b> { assetSensor.created_at }</p>
                                </div>
                            </Card>
                        </div>
                    )
                })
            }

            <DeleteSensorModal 
                className="modal-delete-sensor" 
                title={<IntlMessages id="propertyPage.mobModal.sensorTab.deleteButton" />}
                visible={visibleDeleteSensor} 
                onOk={handleDeleteSensor} 
                onCancel={handleCancelSensor}>
                <p>{<IntlMessages id="propertyPage.mobModal.sensorTab.confirmDeleteText" />}</p>
            </DeleteSensorModal>
        </SensorWrap>
    )
}

export default SensorList;

const DeleteSensorModal = styled(Modal)`
  .ant-btn{
    color: #559f2f;
    border-color:#559f2f;
  }  
  .ant-btn.ant-btn-primary,.ant-btn.ant-btn-primary:hover{
    background:#65bb38;
    color: #fff;
  }   
  
  .ant-btn:hover{
    color: #559f2f;
    border-color:#559f2f;
  }
`;

import axios from "axios";
import { apiEndpoint } from '@iso/lib/helpers/endpoint';
import { authHeader } from '@iso/lib/helpers/authHeader';

export const device = {
  getList
};

function getList(propertyId){
  return (
    axios
      .get(
        `${apiEndpoint.device}?property_id=${propertyId}`,
        {
          headers: authHeader(),
        }
      ).then(res => {
        if(res && res.status === 200 ){
          return res.data;
        }
      })
  );
}

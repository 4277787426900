import styled from 'styled-components';
import Modal from '@iso/components/Feedback/Modal';
import WithDirection from '@iso/lib/helpers/rtl';

const AddDeviceModalWrapper = styled(Modal)`
  .ant-device-search,.ant-device-search:hover{
    border-color: #729D68;
    border-radius: 5px;
    padding: 8px;
  }
  .ant-device-search .ant-input-group{
    border: 1px solid #729D68;
  }
  .ant-device-search .ant-input {
    border: 0px !important;
  }
  .ant-input{
    padding: 8px;
    width: 100%;
  }
`;

export default WithDirection(AddDeviceModalWrapper);

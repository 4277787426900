import styled from 'styled-components';
import WithDirection from '@iso/lib/helpers/rtl';

const SensorWrap = styled.div`
  max-height:400px;
  overflow:auto;

  .btn-success{
    background-color: #65bb38;
    border-color: #559f2f;
    &:hover{
      opacity: 0.8;
    }
  }
  .btn-n-br{
    border:none;
  }
  .wrap-card-sensor{
    position:relative;
  }
  .btn-edit-sensor, .btn-view-sensor{
    color: #559f2f;
  }
  .btn-delete-sensor{
    color: red;
  }
  .card-sensor-title{
    text-transform: capitalize;
    margin-bottom: 20px;
  }

  a,.ant-btn:hover{
    border-color: #559f2f;
    color:#559f2f
  }
  
  .attachment-sensor{
    text-transform: none;
    color: #559f2f;
  }
  .wrap-btn-sensor {
    position: absolute;
    top: 10px;
    right: 0;
    z-index: 1;
  }
  .ant-modal-body{
    padding: 0;
  }
  .form-title{
    padding: 16px 24px;
    border-bottom: 1px solid #eaeef1;
    h3{
      font-size: 1rem;
    }
  }
  .form-body{
    padding: 12px 24px 12px 24px;
    .checkbox-contact{
      margin-bottom: 10px;
    }
  }
  
`;

export default WithDirection(SensorWrap);
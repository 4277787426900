import React, { useState, useEffect, useCallback } from "react";
import { Form, Button } from "antd";
import IntlMessages from "@iso/components/utility/intlMessages";
import FormModalWrapper from "./FormModal.styles";
import { useSelector } from "react-redux";
import _ from "lodash";
import BioForm from "@iso/containers/Pages/AdminPages/FormBuilder/BioForm/BioForm";
import DragDropQuestion from "@iso/components/DragDropQuestion/DragDropQuestion";
import CheckinForm from '@iso/containers/Pages/AdminPages/FormBuilder/CheckinForm/CheckinForm';
import SurveyFormResult from "./SurveyFormResult";
import TrainingForm from "@iso/containers/Pages/AdminPages/FormBuilder/TrainingForm/TrainingForm";
import IllnessForm from "@iso/containers/Pages/AdminPages/FormBuilder/IllnessForm/IllnessForm";
import NearMissForm from "@iso/containers/Pages/AdminPages/FormBuilder/NearMissForm/NearMissForm";

const ViewSurveyResultModal = (props) => {
  const activePropertyId = useSelector(
    (state) => state.property.activePropertyId
  );
  const currentUser = useSelector((state) => state.user.user);
  const [form] = Form.useForm();
  const permissions = useSelector((state) => state.permission.permissions);
  const [showForm, setShowForm] = useState(false);
  const [fields, setFields] = useState([]);
  const [result, setResult] = useState([]);
  const [globalFormResult, setGlobalFormResult] = useState([]);

  const cancelModal = () => {
    props.cancelViewSurveyResult();
  };

  useEffect(() => {
    if (props.surveyData) {
      if (props.type == "self_create") setResult(props.surveyData.answer_list);
      if (props.type == "system_forms")
        setGlobalFormResult(
          props.surveyData.global_form_submission
        );
    }
  }, [props.surveyData]);

  const generateContent = (metaContent) => {
    if(!metaContent) return [];
    var contentArr = Object.keys(metaContent).map((key) => [
      key,
      metaContent[key],
    ]);
    // console.log(contentArr)
    // var content = '';
    // _.forEach(contentArr, (value, index) => {
    //   content += (value[0].replace("_", " ")[0].toUpperCase() + value[0].replace("_", " ").substring(1)  + ': ' + value[1] + (index != contentArr.length - 1 ? ", " : ''));
    // })

    return contentArr;
  };

  const renderAnswer = (answer) => {
    if (typeof answer === "object" && answer.signatures) {
      return (
        <div>
          {answer.signatures.map((signature) => {
            return (
              <>
                <img
                  src={signature.full_path}
                ></img>
                <p>{signature.name}</p>
              </>
            );
          })}
        </div>
      );
    }
    //render drag&drop
    if (typeof answer === "object" && answer.question) {
      const value = {
        question: answer.question,
        options: answer.options,
        optionInfo: answer.optionInfo,
        questionInfo: answer.questionInfo,
      }
      return (
        <DragDropQuestion value={value} onChange={()=>{}}></DragDropQuestion>
      );
    }
    return <b> {answer.toString()}</b>;
  };

  const renderGlobalFormResult = () => {
    return (
      <Form
        form={form}
        layout="vertical"
        scrollToFirstError
        fields={fields}
        className="form-body"
      >
        <div style={{ overflow: "scroll", width: "100%", height: "600px" }}>
          {result
            ? result.map((value, index) => {
                return (
                  <>
                    <h3>Submission #{ index + 1 }:</h3>
                    {value.map((submission, index2) => {
                      return (
                        <>
                          <h3>{submission.question_content}</h3>Answer:{" "}
                          <b>{submission.answer_content}</b>
                          <br />
                          <br />
                        </>
                      )
                    })}
                    <hr/>
                  </>
                );
              })
            : ""
          }
          {
            (globalFormResult) ? renderSystemFormResult(globalFormResult) : ''
          }
        </div>
      </Form>
    );
  }

  const renderSystemFormResult = (resultData) => {
    var newResult = [];
    if (Array.isArray(resultData)) 
    {
      resultData.map((value, index) => {
        var value = generateContent(value);

        newResult.push (
            <>
              <h3>Submission #{ index + 1 }:</h3>
              {value.map((submission, index2) => {
                return (
                 <>
                  <h3>
                    {submission[0].replace("_", " ")[0].toUpperCase() + submission[0].replace("_", " ").substring(1).replace(/(---.*)$/g, "")  + ': '}
                  </h3>
                    Answer: {renderAnswer(submission[1])}<br/><br/>
                  </>
                )
              })}
              <hr/>
            </>
          )
        }
      )
    } else {
      var resultData = generateContent(resultData);

      resultData.map((submission, index) => {
        newResult.push(
          <>
            <h3>
              {submission[0].replace("_", " ")[0].toUpperCase() + submission[0].replace("_", " ").substring(1).replace(/(---.*)$/g, "")  + ': '}
            </h3>
              Answer: {renderAnswer(submission[1])}<br/><br/>
          </>
        )
      });
    }

    return newResult;
  };

  const renderFormResult = (type) => {
    switch (type) {
      case "checkin_form":
        return (
          <CheckinForm
            formData={props.surveyData.global_form_submission}
          ></CheckinForm>
        );
      case "bio_form":
        return (
          <BioForm formData={props.surveyData.global_form_submission}></BioForm>
        );
      case "hazard_form":
        return (
          <SurveyFormResult formType="hazard" formData={props.surveyData.global_form_submission} generateContent={generateContent} renderAnswer={renderAnswer}></SurveyFormResult>
        );
      case "training_form":
        return (
          <SurveyFormResult formType="training" formData={props.surveyData.global_form_submission} generateContent={generateContent} renderAnswer={renderAnswer}></SurveyFormResult>
        );  
      case "illness_form":
        return (
          <SurveyFormResult formType="illness" formData={props.surveyData.global_form_submission} generateContent={generateContent} renderAnswer={renderAnswer}></SurveyFormResult>
        );  
      case "near_miss_form":
        return (
          <SurveyFormResult formType="near_miss" formData={props.surveyData.global_form_submission} generateContent={generateContent} renderAnswer={renderAnswer}></SurveyFormResult>
        );  
      default:
        return renderGlobalFormResult();
    }
  };


  return (
    <FormModalWrapper
      width={1200}
      className={props.type === 'bio_form' ? 'bio-form-result' : ''}
      forceRender={true}
      maskClosable={false}
      visible={props.visibleViewSurveyResult}
      title={<IntlMessages id="formPage.modal.surveyResult" />}
      onCancel={cancelModal}
      footer={[
        <Button key="back" onClick={cancelModal} className="cancel-button">
          {<IntlMessages id="propertyPage.modal.cancel" />}
        </Button>
      ]}
    >
      
      {renderFormResult(props.type)}
    </FormModalWrapper>
  );
};

export default ViewSurveyResultModal;

import React, { useState, useEffect } from 'react';
import { Col, Row, DatePicker } from 'antd';
import Box from '@iso/components/utility/box';
import LayoutWrapper from "@iso/components/utility/layoutWrapper.js";
import SensorsPage from "./Sensors.styles";
import moment from "moment";
import {
    LineChart,
    Line,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    ResponsiveContainer,
    BarChart,
    Bar,
    Rectangle
} from "recharts";
import { sensorService } from '../../../services/sensor.service';
import { useParams } from 'react-router-dom';

const { RangePicker } = DatePicker;

const dateFormat = 'DD/MM/YYYY';
const nowDate = moment().format(dateFormat);
const defaultDate = moment(nowDate, dateFormat);

const SensorDetail = (props) => {
    const barChartData = [
        {
            name: "Min",
            value: 27,
        },
        {
            name: "Max",
            value: 32,
        },
        {
            name: "Average",
            value: 29,
        },
    ];

    const lineChartData = [
        {
            startTime: '11:00',
            value: 27,
        },
        {
            startTime: '12:00',
            value: 32,
        },
        {
            startTime: '13:00',
            value: 29,
        },
        {
            startTime: '14:00',
            value: 27,
        },
        {
            startTime: '15:00',
            value: 32,
        },
        {
            startTime: '16:00',
            value: 29,
        },
    ];

    const { sensorId } = useParams();

    const [sensor, setSensor] = useState({});
    const [items, setItems] = useState([]);
    const [sensorData, setSensorData] = useState(null);
    const [loading, setLoading] = useState(false);
    const [startDate, setStartDate] = useState(nowDate);
    const [endDate, setEndDate] = useState(nowDate);

    useEffect(() => {
        getSensorData();
    }, []);

    const getSensorData = ()=> {
        const params = {
            start_date: startDate,
            end_date: endDate,
        }

        sensorService.getDetail(sensorId, params).then((res) => {
            if (res.code === "0000") {
                setSensor(res.data.sensor);

                setItems(res.data.items);

                setSensorData(res.data.data);
            }
        })
    }

    const handleDateOpenChange = (open) => {
        if (open === false) {
            getSensorData();
        }
    }

    const handleDateChange = (date, dateString) => {
        setStartDate(dateString[0]);
        setEndDate(dateString[1]);
    };

    const renderAllSensorChart = () => {
        return (
            <>
                { renderSingleSensorChart('sensor1') }
                { renderSingleSensorChart('sensor2') }
                { renderSingleSensorChart('sensor3') }
                { renderSingleSensorChart('sensor4') }
                { renderSingleSensorChart('sensor5') }
            </>
        );
    }

    const renderSingleSensorChart = (key) => {
        if (sensor[key + '_part_number'] === 'X50-14002') {
            const temperatureLineData = items.map((item) => ({
                label: item.start_time,
                value: item[key].temperature
            }));

            const humidityLineData = items.map((item) => ({
                label: item.start_time,
                value: item[key].humidity
            }));

            const temperatureBarData = [
                {
                    label: "Min",
                    value: sensorData[key]['min_temperature']
                },
                {
                    label: "Max",
                    value: sensorData[key]['max_temperature']
                },
                {
                    label: "Average",
                    value: sensorData[key]['avg_temperature']
                },
            ];

            const humidityBarData = [
                {
                    label: "Min",
                    value: sensorData[key]['min_humidity']
                },
                {
                    label: "Max",
                    value: sensorData[key]['max_humidity']
                },
                {
                    label: "Average",
                    value: sensorData[key]['avg_humidity']
                },
            ];

            return (
                <>
                    <Row gutter={15} className="manage-table-row" style={{ marginTop: 15 }}>
                        <Col xs={24}>
                            <h2 style={{ color: 'rgb(114, 157, 104)' }}>Temperature & Humidity Sensor</h2>
                        </Col>
                    </Row>
                    { renderTemperatureAndHumidityChart('Temperature', '°C', temperatureBarData, temperatureLineData) }
                    { renderTemperatureAndHumidityChart('Humidity', '%', humidityBarData, humidityLineData) }
                </>
            );
        }

        return null;
    }

    const renderTemperatureAndHumidityChart = (title, unit, barChartData, lineChartData) => {
        return (
            <Row gutter={15} className="manage-table-row">
                <Col xs={24}>
                    {title}
                </Col>

                <Col xl={6} md={24} sm={24} xs={24}>
                    <ResponsiveContainer width="100%" height="100%">
                        <BarChart
                            data={barChartData}
                        >
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="label" />
                            <YAxis unit={unit} />
                            <Tooltip formatter={(value) => `${value}${unit}`} />
                            <Legend />
                            <Bar
                                name={title}
                                dataKey="value"
                                fill="#B3CDAD"
                                activeBar={<Rectangle fill="pink" stroke="blue" />}
                            />
                        </BarChart>
                    </ResponsiveContainer>
                </Col>

                <Col xl={18} md={24} sm={24} xs={24}>
                    <ResponsiveContainer width="100%" height={400}>
                        <LineChart
                            data={lineChartData}
                        >
                        <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="label" />
                            <YAxis unit={unit} />
                            <Tooltip formatter={(value) => `${value}${unit}`} />
                            <Legend />
                            <Line
                                name={title}
                                type="monotone"
                                dataKey="value"
                                stroke="#729D68"
                                activeDot={{ r: 8 }}
                            />
                        </LineChart>
                    </ResponsiveContainer>
                </Col>
            </Row>
        );
    }

    return (
        <LayoutWrapper>
            <SensorsPage>
                <Box id="containerBox">
                    <div className="manage-property-header">
                        <div className="mp-title">
                            <h2 className="manage-property-title">{sensor.device_id}</h2>
                        </div>
                            
                        <div className="button-group">
                            <RangePicker
                                defaultValue={[defaultDate, defaultDate]}
                                format={dateFormat}
                                onChange={handleDateChange}
                                onOpenChange={handleDateOpenChange}
                            />
                        </div>
                    </div>

                    <div style={{padding: '15px'}}>
                        { (items.length > 0 && sensorData)
                            ? renderAllSensorChart()
                            : <div>No data available.</div>
                        }
                    </div>
                </Box>
            </SensorsPage>
        </LayoutWrapper>
    )
};

export default SensorDetail;